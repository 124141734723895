import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static classes = ['activeLink', 'activeTab']
  static targets = ["btn", "tab"]
  static values = {defaultTab: String}

  declare btnTargets: HTMLElement[]
  declare tabTargets: HTMLElement[]
  declare defaultTabValue: string
  declare activeLinkClasses: string[]
  declare activeTabClasses: string[]

  connect() {
    // first, hide all tabs
    this.tabTargets.map(x => this.hideTab(x.id))

    // then, show the default tab
    let selectedTab = this.tabTargets.find(element => element.id === this.defaultTabValue)
    if (selectedTab) { this.showTab(selectedTab.id) }

    // and activate the selected button
    let selectedBtn = this.btnTargets.find(element => element.id === this.defaultTabValue)
    if (selectedBtn) { selectedBtn.classList.add(...this.activeLinkClasses) }
  }

  // switch between tabs
  // add to your buttons: data-action="click->tabs#select"
  select(event: Event) {
    // find tab matching (with same id as) the clicked btn
    let selectedTab = this.tabTargets.find(element => element.id === event.currentTarget.id)
    if (selectedTab && !selectedTab.classList.contains("show")) {
      // hide everything
      this.tabTargets.map(x => this.hideTab(x.id)) // hide all tabs
      this.btnTargets.map(x => x.classList.remove(...this.activeLinkClasses)) // deactive all btns

      // then show selected
      this.showTab(selectedTab.id) // show current tab
      event.currentTarget.classList.add(...this.activeLinkClasses) // activate current button
    }
  }

  hideTab(tabId: string) {
    let selectedTab = this.tabTargets.find(element => element.id === tabId)
    if (selectedTab) { selectedTab.classList.remove(...this.activeTabClasses) }
  }

  showTab(tabId: string) {
    let selectedTab = this.tabTargets.find(element => element.id === tabId)
    if (selectedTab) { selectedTab.classList.add(...this.activeTabClasses) }
  }
}
