import { Application } from "@hotwired/stimulus"

import JobOptimizerResultController from "./job-optimizer-result-controller"
import CollapseCheckController from "./collapse_check_controller"
import BmsWidgetFormController from "./bms-widget-form-controller"
import OpenModalController from "./open-modal-controller"
import PasswordMaskToggleController from "./password_mask_toggle_controller"
import IframeResizerController from "./iframe-resizer-controller"
import TabsController from "./tabs-controller"
import CopyButtonController from "./copy-button-controller"
import TranslateController from "./translate_controller"
import AlertCloseController from "./alert_close_controller"
import HighchartsController from "./highcharts_controller"
// import TagCloudController from "./tagcloud_controller"

const Stimulus = Application.start()
Stimulus.register("job-optimizer-result", JobOptimizerResultController)
Stimulus.register("collapse-check", CollapseCheckController)
Stimulus.register("bms-widget-form", BmsWidgetFormController)
Stimulus.register("open-modal", OpenModalController)
Stimulus.register("password-mask-toggle", PasswordMaskToggleController)
Stimulus.register("iframe-resizer", IframeResizerController)
Stimulus.register("tabs", TabsController)
Stimulus.register("copy-button", CopyButtonController)
Stimulus.register("translate", TranslateController)
Stimulus.register("alert-close", AlertCloseController)
Stimulus.register("highcharts", HighchartsController)
// Stimulus.register("tagcloud", TagCloudController)
